import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import MUITextField from '@mui/material/TextField';
import { GridCell } from '../Grid';
import SearchField from '../SearchField';
import Chip from '../Chip';
import Skeleton from '../SkeletonLoader/Skeleton';
import Checkbox from '../Checkbox';
import coalesceWithEmptyString from '../lib/coalesceWithEmptyString';

const FullWidthSearchField = styled(SearchField)`
  width: 100%;
`;

const ContainerScrollable = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 42px;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;

  @media (min-width: 1024px) {
    width: 125%;
    transform: translateX(-10%);
    flex-wrap: wrap;
    justify-content: center;
    overflow-x: visible;
  }
`;

const StyledChip = styled(Chip)`
  font-weight: 500;
  font-size: .85rem;
`;

const searchPropertyTypes = {
  keywords: PropTypes.string,
  searchFieldTitle: PropTypes.string.isRequired,
  onSearchTrailingIconSelect: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  locationSearch: PropTypes.string,
  locationSearchFieldTitle: PropTypes.string.isRequired,
  onLocationSearchTrailingIconSelect: PropTypes.func.isRequired,
  onLocationSearchChange: PropTypes.func.isRequired,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      selected: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired,
  ),
  showLoader: PropTypes.bool,
  handleLocaleFilterChange: PropTypes.func,
  showJobsForAllLocales: PropTypes.bool,
  checkboxLabel: PropTypes.string,
};

type SearchProperties = InferProps<
typeof searchPropertyTypes
> & {
  showJobsForAllLocales?: boolean;
};

const SearchSkeleton = () => (
  <Skeleton variant="rectangular" width="100%">
    <MUITextField />
  </Skeleton>
);

const Search = ({
  keywords,
  searchFieldTitle,
  onSearchTrailingIconSelect,
  onSearchChange,
  locationSearch,
  locationSearchFieldTitle,
  onLocationSearchTrailingIconSelect,
  onLocationSearchChange,
  categoryOptions,
  cities,
  showLoader,
  handleLocaleFilterChange,
  showJobsForAllLocales,
  checkboxLabel,
}: SearchProperties) => (
  <>
    <GridCell sm={12} lg={6} padding="0 12px 8px">
      {showLoader ? <SearchSkeleton /> : (
        <FullWidthSearchField
          id="jobs-search-text"
          name="search"
          label={searchFieldTitle}
          value={coalesceWithEmptyString(keywords)}
          onChange={onSearchChange}
          onClear={onSearchTrailingIconSelect}
        />
      )}
    </GridCell>
    <GridCell sm={12} lg={6} padding="0 12px 8px">
      {showLoader ? <SearchSkeleton /> : (
        <FullWidthSearchField
          id="jobs-search-location"
          label={locationSearchFieldTitle}
          value={coalesceWithEmptyString(locationSearch)}
          onChange={onLocationSearchChange}
          onClear={onLocationSearchTrailingIconSelect}
        />
      )}
    </GridCell>
    {
      cities?.[0] ? (
        <GridCell sm={12} lg={12} padding="0 12px 8px">
          <ContainerScrollable>
            {cities.map(({
              id,
              name,
              onClick,
              selected,
            }) => (
              <StyledChip
                key={id}
                onClick={onClick}
                label={name}
                color={selected ? 'primary' : 'default'}
              />
            ))}
          </ContainerScrollable>
        </GridCell>
      ) : (showLoader ? (
        <Skeleton variant="rectangular" width="100%">
          <StyledChip />
        </Skeleton>
      ) : <GridCell sm={12} lg={12} padding="0 12px 8px" />)
    }
    <GridCell sm={12} lg={12} padding="0 12px 8px">
      <ContainerScrollable>
        {categoryOptions.map(({
          id,
          label,
          onClick,
          selected,
        }) => (
          <StyledChip
            key={id}
            onClick={onClick}
            label={label}
            color={selected ? 'primary' : 'default'}
          />
        ))}
      </ContainerScrollable>
    </GridCell>
    {handleLocaleFilterChange && checkboxLabel && (
      <GridCell sm={12} padding="0 0 12px">
        <Checkbox
          checked={showJobsForAllLocales}
          label={checkboxLabel}
          onChange={handleLocaleFilterChange}
        />
      </GridCell>
    )}
  </>
);

Search.propTypes = searchPropertyTypes;

export default Search;
